import axios, { AxiosRequestConfig } from 'axios'
import { ElLoading } from 'element-plus'
import store from '@/store'
// import { useRouter } from "vue-router";
// import router from '../router'

axios.defaults.baseURL = '/api/admin'

let loadingInstance: any


axios.interceptors.request.use((config: AxiosRequestConfig) => {    // 这里的config包含每次请求的内容
  const token: string = store.getters.token  // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJPcGVuSWQiOiI4ZDA4Y2MwMGM4NWY0M2JlOWMzOWI1NjgyOGI5NzRiNCIsImFwcGlkIjoiOWQxNTA2Yzg5YWM3OTFjNCIsImV4cCI6MTYzNDYxMzIyMn0.g9It1ov56bfUi5fxCiXvx7IT2t-yADw3GuQaUdysaEA"//window.sessionStorage.getItem("token")
  console.info(token)
  config.headers!.Authorization = 'Bearer ' + `${token}`
  loadingInstance = ElLoading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  // toast = Toast.loading({
  //   message: '加载中...',
  //   duration: 10000,
  //   forbidClick: true,
  // });
  return config;
}, function (err) {
  if (loadingInstance) loadingInstance.close()
  return Promise.reject(err);
})

// //拦截器
axios.interceptors.response.use(
  response => {
    console.log(response);
    if (loadingInstance) loadingInstance.close()
    return response
  },
  error => {
    console.log(error)
    if (loadingInstance) loadingInstance.close()
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          //confirm('登录已过期')
          // router.push('/login')
          location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            "/login?redirect=" +
            window.location.href;
          break;
        default:
          return error.response;
        //return Promise.reject(error)

        //confirm('登录已过期')
        // router.push('/login')
        // location.href =
        //   "http://token.xjjkzs.com/WechatIntegral/Login?Spm=wxdcd9a0bf1d353677&ReturnUrl=" +
        //   window.location.href;
      }
    }
    //return Promise.reject(error)   // 返回接口返回的错误信息
  })


export class Request {

  static imgpost = (url: string, data?: any)  => {
    // console.info(url)
    
    return new Promise((resolve, reject) => {
      // axios.defaults.baseURL = "/api/img"
          const formData = new FormData();
          formData.append('file',data); // 假设你的文件在 data 对象的 file 属性中
          console.log("------------------分割线1-------------------");
          console.info(data)
          // console.info(formData)
          console.log("------------------分割线-------------------");
          console.log(formData.get("file")); // 打印返回结果
          axios({
          method: 'post',
          url: url, // 设置上传接口地址
          data: formData,
          })
          .then((response) => {
            resolve(response.data);
          //   console.log("response.data");
          
          // console.log("------------------分割线1-------------------");
          // console.log(response); // 打印返回结果
          })
          .catch((error) => {
            reject(error)
          // console.error("error");
          // console.error(error.data);
          });
    })
  }
  static get = (url: string, params?: any) => {
    return new Promise((resolve, reject) => {
      axios.defaults.baseURL = '/api/admin'
      axios.get(url, { params: params }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  }

  static post = (url: string, data?: any) => {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios.defaults.baseURL = '/api/admin'
      axios.post(url, data).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err)
      })
    })
  }

  static put = (url: string, data?: any) => {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios.defaults.baseURL = '/api/admin'
      axios.put(url, data).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err)
      })
    })
  }

  static delete = (url: string) => {
    return new Promise((resolve, reject) => {
      axios.defaults.baseURL = '/api/admin'
      axios.delete(url).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err)
      })
    })
  }

  static http2 = (method: string, url: string, data?: any) => {
    console.log(data);
    console.log(url);
    if (method == "GET") {
      return new Promise((resolve, reject) => {
        axios({
          method: method,
          baseURL: '/token',
          url: url,
          params: data,
        }).then(res => {
          resolve(res.data);
        }).catch(err => {
          reject(err)
        })
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios({
          method: method,
          baseURL: '/token',
          url: url,
          data: data,
        }).then(res => {
          resolve(res.data);
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}

export default axios
